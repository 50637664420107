import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer"
import Competitions from '../components/Competitions';
import Topbar from '../components/Topbar';

const CompetitionsPage = () => {
    return (
        <Layout pageTitle="Shanti Asiatic School | Competitions">
            <Topbar />
            <NavOne />
            <PageHeader title="Competitions" />
            <Competitions />
            <Footer />
        </Layout>
    );
};

export default CompetitionsPage;
